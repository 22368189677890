// This is the homepage.

import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"



const Heading1 = styled.h1`
    font-size: 1.8em;
    padding: 10px;
    text-align: center;
    width: 100%;
    min-height: 85px;
    margin: auto;
    font-weight:600;


`

const Heading2 = styled.h2`
    font-size: 1.4em;    
    padding: 10px;
    text-align: center;
    width: 100%;
    min-height: 85px;
    margin: auto;
    font-weight:500;

`

const Wrapper = styled.div`
    width: 100%;
    background-position: center;
    padding: 10px;
    padding: 40px 40px 40px 40px;
    

`

const ContentWrapper = styled.div`
    text-align: left;
    padding: 30px 40px 30px 40px;
    margin: auto;
`

const Content = styled.p`
    width: auto;
    font-size: 1.3em;
    line-height: 30px;
    padding: 30px 0px 30px 0px;

`


class BlogIndex extends React.Component {
  render() {

    return (

      <Layout location={this.props.location}>
        <SEO title="Terms of Service" />
         <Wrapper><Heading1>Terms of Service</Heading1>
         <ContentWrapper>
           <Heading2>Shipping</Heading2>
            <Content> Express Shipping 1-3 business days* $15.00 fixed rate <br></br><br></br>
* Rural and remote areas may attract longer shipping times but we make every effort to ensure we provide the fastest delivery times possible.
<br></br><br></br>
Delivery times are estimates only. Delivery times apply from the date of dispatch, not the date orders are received and refer to business days not calendar days. We make every attempt to ensure all deliveries arrive within the estimated time frames above, however sometimes events occur that are out of our control.</Content>
            <Heading2>Who we are  </Heading2>
            <Content> Fine Arabica coffee is our quality ingredient that we handle with utmost care; stringent quality control is our hallmark. We enjoy our role in the preparation of traditional artisan espresso coffee, from sourcing and roasting to the final cup. </Content>
            <Heading2>Returns </Heading2>
            <Content> We have strict quality control systems in place. All coffee roasted must meet these high standards or it will not be dispatched. if you experience any issues with your coffee or if there is an error with your order, please contact us at <a href="mailto:TRADING@JBCOFFEE.COM.AU">trading@jbcoffee.com.au</a></Content>
            <Heading2>Security</Heading2> 
            <Content> We use Square to process payments. Square uses PCI compliant technology to keep your information secure, reducing the risk of online fraud. We pay a fee to the payment processor on each transaction, and rely on their expertise in secure payment processing, allowing us to stick to what we do best, delivering fresh coffee.<br></br><br></br>
<br></br>Your card information is not captured or stored at any time on our website.  To find out more about the security that Square applies to your payment, please visit their websites.</Content>
            <Heading2>Currency</Heading2> 
            <Content> All prices on the Bailey Coffee website are in Australian Dollars (AUD) and are inclusive of the Goods and Services Tax (GST). </Content>
            <Heading2>Liability</Heading2> 
            <Content> We try to keep things running smoothly, but sometimes mistakes or other problems happen.  We'll do our best to fix them, but (except for mandatory legal requirements that cannot be excluded) we don't have any liability for errors, bugs or outages.<br></br><br></br>
If you purchase something from our online store, we will always honour our obligations to you under applicable consumer law, and any other legal rights that you have that cannot be legally excluded.  However, any and all other liability that we have to you in connection with your purchase (or the product you purchased) will be capped at the amount you paid us for that purchase (including shipping).  To the extent legally permissible, we will not be liable for any indirect, consequential or special losses, loss of profits, savings or revenue, lost data, lost use, replacement costs, or similar.<br></br><br></br>
We are not responsible for a failure to perform an obligation set out in these terms, where the failure was caused by something beyond our reasonable control (like a natural disaster, a third party infrastructure or service provider failure, wars, strikes and so on).
</Content>
            
            </ContentWrapper>
          </Wrapper>
      </Layout>
    )
  }
}

export default BlogIndex